import axios from 'axios';
import { createStore } from 'vuex'
const api = require("../../apiendpoint.js");

export const store = createStore({
  state() {
    return {
      // Motivo de Ingreso.
      motivo: null,
      resultado: null,
      datosScan: { run: "", ser: 1, raw: "", scanOK: true },
      // Manejo de Token.
      token: '',
    }
  },
  mutations: {
    initialiseStore(state) {
      // Check if the ID exists
      if (sessionStorage.getItem('store')) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(sessionStorage.getItem('store')))
        );
      }
    },
    setToken(state, token) {
      state.token = token;
    },
    setMotivo(state, motivo) {
      state.motivo = motivo;
    },
    setResultado(state, resultado) {
      state.resultado = resultado;
    },
    setDatosScan(state, decodedString) {
      state.datosScan.raw = decodedString.toString();
      if (decodedString.toString().includes("?")) {
        state.datosScan.scanOK = true;
        let queryString = decodedString.toString().split('?');
        if (queryString.length > 1) {
          if (queryString[0] == "https://portal.sidiv.registrocivil.cl/docstatus") {
            const urlParams = new URLSearchParams(queryString[1]);
            state.datosScan.run =  urlParams.get("RUN");
            state.datosScan.ser =  urlParams.get("serial");
          } else {
            console.log(queryString[1]);
          }
        } else {
          state.datosScan.scanOK = false;
        }
      } else {
        state.datosScan.scanOK =  false;
      }     
    }
  },
  actions: {
    validate: async (context) => {
      if (context.state.datosScan.scanOK) {
        let endp = api.apiendpoint + "/validation";
      const config = {
      //   headers: { Authorization: `Bearer ${self.$store.state.token}` },
      };
      const params = {
        "cedula": {
          "run": context.state.datosScan.run,
          "ser": context.state.datosScan.ser
        },
        "motivo": context.state.motivo,
        "raw": context.state.datosScan.raw        
      };
      await axios
        .post(endp, params, config)
        .then((response) => {
          console.log("Response", response);
          if (response.data.RespuestaMinsal) {
            context.commit('setResultado', "AUTORIZADO");
          }else{
            context.commit('setResultado', "DENEGADO, " + response.data.message);
          }
          
          console.log("Valor: ", context.state.resultado);
        })
        .catch((error) => {
          context.commit('setResultado', "NO CONFIRMADO. Hubo un problema en la comunicación, favor reintente");
          console.log("Error: ", error);
        });
      } else {
        context.commit('setResultado', "NO CONFIRMADO. No se ha leído correctamente el QR de la cédula");
      }
      
    }
  },
});