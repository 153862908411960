// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme: {
        dark: false,
        colors: {
          // Colores: https://vuetifyjs.com/en/styles/colors/#material-colors
          // background: '#ECEFF1',
          // surface: '#CFD8DC',
          background: '#FFFFFF',
          surface: '#ECEFF1',
          primary: '#2196F3',
          'primary-darken-1': '#1E88E5',
          secondary: '#64B5F6',
          'secondary-darken-1': '#1976D2',
          error: '#F44336',
          info: '#03A9F4',
          success: '#009688',
          warning: '#FFC107',
        }
      }
    }
  }
})
