import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: '/',
    alias: '/mainview',
    name: 'MainView',
    component: () => import('../components/MainView.vue'),
  },
  {
    path: '/scanview',
    name: 'ScanView',
    component: () => import('../components/ScanView.vue'),
  },
  {
    path: '/helpview',
    name: 'HelpView',
    component: () => import('../components/HelpView.vue'),
  },
  {
    path: '/resultsview',
    name: 'ResultsView',
    component: () => import('../components/ResultsView.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../components/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  //history: createWebHashHistory(),
  routes,
});

export default router;