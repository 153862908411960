<template>
  <v-app id="app">
    <!-- <TemplateHeader></TemplateHeader> -->
    <v-main
      class="align-center pa-auto ma-auto"
    >
      <router-view></router-view>
    </v-main>
    <!-- <TemplateFooter></TemplateFooter> -->
  </v-app>
</template>

<script>
// import TemplateHeader from "./components/Template/Header.vue";
// import TemplateFooter from "./components/Template/Footer.vue";


export default {
  name: "App",
  data() { },
  // components: {
  //   TemplateHeader,
  //   TemplateFooter,
  // },
  methods: {
  },
  beforeCreate() {
    document.title = "Control de Acceso";
    this.$store.commit("initialiseStore");
    // Se imprime aquí, por que no se usará aun
    // console.log("Permisos: ", permissions);
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      // Store the state object as a JSON string
      sessionStorage.setItem("store", JSON.stringify(state));
    });

  },
};
</script>

<style>

</style>